/* THEME COLORS */

$light-blue: #3da7d3;
$dark-blue: #034a61;
$header-links-blue: #3da7d8;
$footer-green: #133d50;
$orange: #f05331;
$red: #d41e47;
$gray: #666;
$light-gray: #ccc;
$modal-gray: #56575c;
$off-white: #eee;

/* THEME FONTS  */

$font-family-body: Arial, Helvetica, sans-serif;
$font-family-heading: 'Verlag A', Arial, Helvetica, sans-serif;
